@use './breakpoints';

@mixin align() {
  max-width: 1110px;
  padding: 0 1.25rem 0;
  
  margin: 0 auto;

  @include breakpoints.mq($from: 'tablet') {
    padding: 0 2.5rem 0;
  }

  @include breakpoints.mq($from: 'laptop') {
    padding: 0 3.55rem 0;
  }

  @include breakpoints.mq($from: 'desktop') {
    padding: 0;
  }
}

@mixin normalBleed() {
  max-width: 1110px;
  padding-left: 1.25em;
  padding-right: 1.25em;  
  margin-left: auto;
  margin-right: auto;

  @include breakpoints.mq($from: 'tablet') {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  @include breakpoints.mq($from: 'laptop') {
    padding-left: 2em;
    padding-right: 2em;
  }

  @include breakpoints.mq($from: 'desktop') {
    padding: 0;
  }
}

@mixin headerNavAlign() {
    @include breakpoints.mq($from: 'tablet') {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  
    @include breakpoints.mq($from: 'laptop') {
      padding-left: 3.75rem;
      padding-right: 3.75rem;
    }
  
    @include breakpoints.mq($from: 'desktop') {
      padding-left: 10rem;
      padding-right: 10rem;
    }
  
    @include breakpoints.mq($from: 'projector') {
      padding-left: 16rem;
      padding-right: 16rem;
    }
  
    @include breakpoints.mq($from: 'large') {
      padding-left: 25rem;
      padding-right: 25rem;
    }
  }

@mixin defaultSpace($property) {
    #{$property}: 1.25rem;
    @include breakpoints.mq($from: "tablet") {      
      #{$property}: 2.5rem;
    }

    @include breakpoints.mq($from: 'laptop') {
      #{$property}: 3.55rem;
    }

    @include breakpoints.mq($from: "desktop") {
      #{$property}: 0;
    }

    @include breakpoints.mq($from: "large") {
      #{$property}: -16rem;
    }
}

// Font
@mixin fontface($family, $src, $style: normal, $weight: normal) {
    @font-face {
      font-display: swap;
      font-family: $family;
      src: url('#{$src}.woff2') format('woff2'),
        url('#{$src}.woff') format('woff'), url('#{$src}.ttf') format('truetype');
      font-style: unquote($style);
      font-weight: unquote($weight);
    }
}