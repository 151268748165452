@use './mixin';
@use './breakpoints';
@use './functions';

$assetPath: '../../public/cdn/';
$assetPathUtility: '../../../public/cdn/';

$fonts: (
  body: (
    nex-body,
    helvetica,
    arial,
    sans-serif
  ),
  heading: (
    nex-headline,
    helvetica,
    arial,
    sans-serif
  ),
  medium: (
    nex-sans-medium,
    helvetica,
    arial,
    sans-serif
  )
);


@include mixin.fontface(
  'nex-headline',
  '/fonts/headline/nextorysans-headline-regular',
  'normal',
  '400'
);
@include mixin.fontface(
  'nex-headline',
  '/fonts/headline/nextorysans-headline-demibold',
  'normal',
  'bold'
);

@include mixin.fontface(
  'nex-body',
  '/fonts/body/nextorysans-regular',
  'normal',
  '400'
);
@include mixin.fontface(
  'nex-body',
  '/fonts/body/nextorysans-italic',
  'italic',
  '400'
);
@include mixin.fontface(
  'nex-body',
  '/fonts/body/nextorysans-medium',
  'normal',
  'bold'
);

@include mixin.fontface(
  'nex-sans-medium',
  '/fonts/body/nextorysans-medium',
  'normal',
  'bold'
);

// Colors
$colors: (
  body: #fff,
  text: #555,
  heading: #444,
  secondaryTint1: #eeebe8,
  secondaryTint2: #e5d2d3,
  secondaryTint3: #f9f9f9,
  secondaryTint4: #dedcd9,
  primary: #e95164,
  tertiary :#C8C6C3,
  primaryHover: #dc5263,
  border: rgba(#000, 0.1),  
  disclaimer: #585858,  
  secondary2: #703056,  
  smoke: #f4f4f4,
  goldStar: #eaad0d,
  secondary1: #61a4b3,  
  secondary6: #287470,  
  secondaryLight: rgba(#eeebe8, 0.1),
  inputBorder: #ccc,
  warning: #de6868,
  placeholder: rgba(#555, 0.5)
);

// Common sizes
// $sizes: (
//   bodyFontSize: 16px
// );


// Size function
@function size($key) {
  @return map-get(functions.$sizes, $key);
}

// Color function
@function color($key) {
    @return map-get($colors, $key);
}

// Font function
@function font($key) {
    @return map-get($fonts, $key);
}

@mixin typographyDebugger($name) {
  position: relative;
  &:hover {
    background: rgba(#ccc, 0.5);
    &:after {
      content: $name;
      color: #fff;
      background: #000;
      font-family: arial;
      letter-spacing: 0;
      position: absolute;
      top: 50%;
      left: -10px;
      transform: translate(-100%, -50%);
      font-size: px2rem(15px);
      padding: 0.5em;
    }
  }
}

@mixin heading10($margin: false, $color: color("text")) {
  // @include typographyDebugger("heading10");

  font-family: font("heading");
  font-feature-settings: "liga", "clig";
  margin-top: 0;
  margin-bottom: 0;
  font-size: functions.px2rem(25px);
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: -0.1px;
  color: color("heading");

  @include breakpoints.mq($from: "tablet") {
    font-size: functions.px2rem(30px);
  }

  @if ($color) {
    color: $color;
  }

  // Adaptive margin
  @if $margin {
    &:nth-child(1n + 2) {
      margin-top: 2.5rem;
    }

    &:nth-last-child(1n + 2) {
      margin-bottom: 2rem;
    }
  }
}

@mixin headingBook($margin: false) {
  // @include typographyDebugger("headingBook");

  font-family: font("heading");
  font-feature-settings: "liga", "clig";
  margin-top: 0;
  margin-bottom: 0;
  font-size: functions.px2rem(25px);
  font-weight: bold;
  line-height: 1.05;
  letter-spacing: -0.5px;
  color: color("heading");

  @include breakpoints.mq($from: "laptop") {
    font-size: functions.px2rem(40px);
  }

  &--largeDesktop {
    @include breakpoints.mq($from: "desktop") {
      font-size: functions.px2rem(60px);
    }
  }
}

@mixin headingLarge($margin: false, $color: color('heading')) {
  // @include typographyDebugger("heading1");
  font-family: font('heading');
  font-feature-settings: 'liga', 'clig';
  margin-top: 0;
  margin-bottom: 0;
  font-size: functions.px2rem(40px);
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: -0.5px;

  @if ($color) {
    color: $color;
  }
  
  @include breakpoints.mq($from: 'laptop') {
    font-size: functions.px2rem(60px);
  }

  @include breakpoints.mq($from: 'laptop') {
    font-size: functions.px2rem(80px);
  }
}

@mixin heading1($margin: false, $color: color('body')) {
    // @include typographyDebugger("heading1");
    font-family: font('heading');
    font-feature-settings: 'liga', 'clig';
    margin-top: 0;
    margin-bottom: 0;
    font-size: functions.px2rem(40px);
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: -0.5px;

    @if ($color) {
        color: $color;
      }
  
    @include breakpoints.mq($from: 'laptop') {
      font-size: functions.px2rem(60px);
    }
  
    @if $margin {
      &:nth-child(1n + 2) {
        margin-top: 2.5rem;
      }
  
      &:nth-last-child(1n + 2) {
        margin-bottom: 2rem;
      }
    }
  }


  @mixin bodySansMedium($margin: false, $color: color('body')) { 
    font-family: font('medium');
    margin-top: 0;
    margin-bottom: 0;
    font-size: functions.px2rem(14px);
    line-height: 1.29;
    letter-spacing: -0.1px;
  
    @if ($color) {
      color: $color;
    }
  
    // Adaptive margin
    @if $margin {
      &:nth-last-child(1n + 2) {
        margin-bottom: 1rem;
      }
    }
  }

  @mixin bodyText($margin: false, $color: color("text")) {
    font-family: font('body');
    margin-top: 0;
    margin-bottom: 0;
    font-size: functions.px2rem(17px);
    line-height: 1.53;
    letter-spacing: -0.1px;

    @if ($color) {
      color: $color;
    }
  
    // Adaptive margin
    @if $margin {
      &:nth-last-child(1n + 2) {
        margin-bottom: 1rem;
      }
    }
  }
  
  @mixin linksBold($margin: false) {
    // @include typographyDebugger("linksBold");
    font-family: font("heading");
    font-feature-settings: "liga", "clig";
    margin-top: 0;
    margin-bottom: 0;
    font-size: functions.px2rem(14px);
    font-weight: bold;
    line-height: normal;
    letter-spacing: 1px;
    color: color("text");
  }

  @mixin bodyMetaSmall($margin, $one, $two: false) {
    font-family: font("body");
    margin-top: 0;
    margin-bottom: 0;
    font-size: functions.px2rem(12px);
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: -0.09px;
  
    @if $margin {
      margin: $one $two;
    }
  }

  @mixin bodyMeta($margin: false, $color: color('disclaimer')) {
    margin-top: 0;
    margin-bottom: 0;    
    font-size: functions.px2rem(14px);
    line-height: 1.29;
    letter-spacing: functions.px2rem(-0.1px);
  
    @if ($color) {
      color: $color;
    }
  
    // Adaptive margin
    @if $margin {
      &:nth-last-child(1n + 2) {
        margin-bottom: 1rem;
      }
    }
  }

  @mixin bodyMetaMini($margin: false, $color: color("disclaimer")) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: functions.px2rem(13px);
    line-height: 1.29;
    letter-spacing: functions.px2rem(-0.1px);
  
    @if ($color) {
      color: $color;
    }
  
    // Adaptive margin
    @if $margin {
      &:nth-last-child(1n + 2) {
        margin-bottom: 1rem;
      }
    }
  }

  @mixin heading2($margin: false) {
  
    font-family: font('heading');
    font-feature-settings: 'liga', 'clig';
    margin-top: 0;
    margin-bottom: 0;
    font-size: functions.px2rem(30px);
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: functions.px2rem(-0.5px);
    color: color('heading');
  
    @include breakpoints.mq($from: 'laptop') {
      font-size: 40px;
    }
  
    // Adaptive margin
    @if $margin {
      &:nth-child(1n + 2) {
        margin-top: 2.5rem;
      }
  
      &:nth-last-child(1n + 2) {
        margin-bottom: 2.5rem;
  
        + p {
          margin-top: -1rem;
        }
      }
    }
  }

  @mixin heading3($margin: false) {
  
    font-family: font("heading");
    font-feature-settings: "liga", "clig";
    margin-top: 0;
    margin-bottom: 0;
    font-size: functions.px2rem(25px);
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: functions.px2rem(-0.1px);
    color: color("heading");
  
    @include breakpoints.mq($from: "laptop") {
      font-size: functions.px2rem(30px);
    }
  
    // Adaptive margin
    @if $margin {
      &:nth-child(1n + 2) {
        margin-top: 2.5rem;
      }
  
      &:nth-last-child(1n + 2) {
        margin-bottom: 2rem;
  
        + p {
          margin-top: -1rem;
        }
      }
    }
  }

  @mixin heading4($margin: false) {
    font-family: font('heading');
    font-feature-settings: 'liga', 'clig';
    margin-top: 0;
    margin-bottom: 0;
    font-size: functions.px2rem(20px);
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: functions.px2rem(-0.1px);
    color: color('heading');
  
    @include breakpoints.mq($from: 'laptop') {
      font-size: 25px;
    }
  
    // Adaptive margin
    @if $margin {
      &:nth-child(1n + 2) {
        margin-top: 2.5rem;
      }
  
      &:nth-last-child(1n + 2) {
        margin-bottom: 1.5rem;
  
        + p {
          margin-top: -1rem;
        }
      }
    }
  }

  @mixin heading5($margin: false) {
    font-family: font('heading');
    font-feature-settings: 'liga', 'clig';
    margin-top: 0;
    margin-bottom: 0;
    font-size: functions.px2rem(20px);
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: functions.px2rem(-0.1px);
    color: color('heading');
  
    // Adaptive margin
    @if $margin {
      &:nth-child(1n + 2) {
        margin-top: 2.5rem;
      }
  
      &:nth-last-child(1n + 2) {
        margin-bottom: 0.5rem;
      }
    }
  }

  @mixin heading6() {
    // @include typographyDebugger("heading5");
    font-family: font("heading");
    font-feature-settings: "liga", "clig";
    margin-top: 0;
    margin-bottom: 0;
    font-size: functions.px2rem(20px);
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: functions.px2rem(-0.1px);
    color: color("heading");
    @include breakpoints.mq($from: "desktop") {
      font-size: functions.px2rem(25px);
    }
  }
  
  @mixin heading8($color: color('text')) {
    // @include typographyDebugger("heading8");
  
    font-family: font("heading");
    font-feature-settings: "liga", "clig";
    margin-top: 0;
    margin-bottom: 0;
    font-size: functions.px2rem(30px);
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: functions.px2rem(-0.1px);
  
    @include breakpoints.mq($from: "tablet") {
      font-size: functions.px2rem(40px);
    }
  
    @include breakpoints.mq($from: "laptop") {
      font-size: functions.px2rem(60px);
    }

    @if ($color) {
      color: $color;
    }
  }


  @mixin heading9($color: color('heading')) {
    // @include typographyDebugger('heading5');
  
    font-family: font('heading');
    font-feature-settings: 'liga', 'clig';
    margin-top: 0;
    margin-bottom: 0;
    font-size: functions.px2rem(16px);
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: functions.px2rem(-0.1px);    

    @include breakpoints.mq($from: "tablet") {
      font-size: functions.px2rem(25px);
    }
      
    @include breakpoints.mq($from: "laptop") {
      font-size: functions.px2rem(30px);
    }

    @if ($color) {
      color: $color;
    }
  }


  @mixin heading13($margin: false, $color: color("heading")) {
    // @include typographyDebugger("heading3");
  
    font-family: font("heading");
    font-feature-settings: "liga", "clig";
    margin-top: 0;
    margin-bottom: 0;
    font-size: px2rem(25px);
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: px2rem(-0.1px);
  
    @include breakpoints.mq($from: "tablet") {
      font-size: px2rem(30px);
    }
  
    @include breakpoints.mq($from: "laptop") {
      font-size: px2rem(40px);
    }
  
    @if ($color) {
      color: $color;
    }
  
    // Adaptive margin
    @if $margin {
      &:nth-child(1n + 2) {
        margin-top: 2.5rem;
      }
  
      &:nth-last-child(1n + 2) {
        margin-bottom: 2rem;
      }
    }
  }

  @mixin readerPara() {
    // @include typographyDebugger("headingBook");
  
    font-family: font("heading");
    font-feature-settings: "liga", "clig";
    margin-top: 0;
    margin-bottom: 0;
    font-size: functions.px2rem(20px);
    font-weight: bold;
    line-height: 1.4;
    letter-spacing: functions.px2rem(-0.5px);
    color: color("heading");
  
    @include breakpoints.mq($from: "desktop") {
      font-size: functions.px2rem(25px);
    }
  }

  @mixin splash() {  
    font-family: font("heading");
    font-feature-settings: "liga", "clig";
    margin-top: 0;
    margin-bottom: 0;
    font-size: functions.px2rem(16px);
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: functions.px2rem(-0.1px);
    color: color("secondaryTint1");
  
    @include breakpoints.mq($from: "desktop") {
      font-size: 20px;
    }
  }

  @mixin linksBold($margin: false) {
    // @include typographyDebugger('linksBold');
    font-family: font('heading');
    font-feature-settings: 'liga', 'clig';
    margin-top: 0;
    margin-bottom: 0;
    font-size: functions.px2rem(14px);
    font-weight: bold;
    line-height: normal;
    letter-spacing: functions.px2rem(1px);
    color: color('text');
  }


  @mixin tag($margin: false) {
    // @include typographyDebugger('tag');
    font-family: font('body');
    font-feature-settings: 'liga', 'clig';
    margin-top: 0;
    margin-bottom: 0;
    font-size: functions.px2rem(11px);
    line-height: normal;
    letter-spacing: functions.px2rem(-0.1px);
    color: color('secondaryTint1');
  }


  @mixin subscriptionBox() {
    border-radius: 0.3rem;
    padding: 2.5rem 1.25rem 5rem;
  }
