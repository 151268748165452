@use '../lib';


@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }   
}

.featureModule {
  // width: 100%;
  background: lib.color('secondaryTint1');  
  position: relative;
  
  padding: 1px 0;

  

  &__holder{
    max-width: 60rem;
    margin: 0 auto;
    
    
    border-top: 2px solid rgba(0, 0, 255, 0);
    border-bottom: 2px solid rgba(0, 0, 255, 0);

    padding: 2.25rem 1.25rem;

    @include lib.mq($from: 'tablet') {
      padding: 6.25rem 2rem;
    }

    &--spaceTop{
      margin-top: 1.55rem;
    }
  
    &--spaceBottom{
      margin-bottom: 1.55rem;
    }

  }

  &__details{
    display: flex;
    align-items: center;
  }

  &__data{
    flex: 1;
  }

  &__headerTextLabel{
    @include lib.bodyMetaMini();
    display: block;
    margin-bottom: 1.5rem;
    @include lib.mq($from: 'laptop') {
      margin-bottom: 2rem;
    }
    @include lib.mq($from: 'monitor') {
      display: none;
    }
  }

  &__image{
    flex: 1;

    // for hover animation
    // -webkit-transform: perspective(1px) translateZ(0);
    // transform: perspective(1px) translateZ(0);
    // box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    // -webkit-transition-duration: 0.3s;
    // transition-duration: 0.3s;
    // -webkit-transition-property: transform;
    // transition-property: transform;
    // -webkit-transition-timing-function: ease-out;
    // transition-timing-function: ease-out;

    @include lib.mq($until: 'tablet') {
      display: none;
    }
    
      &--animate{ 
          animation-name: floating;
          animation-duration: 5s;
          animation-iteration-count: infinite;
          animation-timing-function: ease-in-out;
          margin-left: 30px;
          margin-top: 5px;

        // for hover animation
        // &:hover{
        //   -webkit-transform: translateY(-8px);
        //   transform: translateY(-8px);
        // }

    }    
  }

  &__list{
    display: flex;
    margin-bottom: 3.125rem;
    &::before{
      content: url('#{lib.$assetPath}/images/shared/icon-ellipse.svg');
      width: 62px;
      height: 62px;
      position: relative;
      top: 8px;
    }

    &:last-child{
      margin-bottom: 0;
    }

    $icons: 
      "avatar",
      "globe",
      "tile",
      "star",
      "pen",
    ;

    @each $icon in $icons {
      &--icon-#{$icon} {
        &::before{
          content: url('#{lib.$assetPath}/images/shared/icon-#{$icon}.svg');
        }
      }
    }    

  }

  &__head{
    @include lib.heading1(false, lib.color('heading'));
    margin-left: 2rem;
  }

  &__numbers{
    visibility: visible; // temporary to avoid CSS warnings
    &--more{
      &::after{
        content: '+';
      }
    }
  }

  &__category{
    @include lib.heading5();
    color: rgba(lib.color('text'), 0.5);
    display: block;
  }

  &::before{
    @include lib.mq($from: 'monitor') {
      @include lib.bodyMetaMini(false, lib.color('text'));
      content: attr(data-numbers);
      position: absolute;
      top: 50%;
      left: 3rem;
      transform-origin: center;
      transform: translate(-50%, -50%) rotate(270deg);
      text-align: center;
      width: 30%;
    }
  }
}
